<template>
  <main class="container">
    <h1>Upcoming Tour Dates</h1>
    <br>
    <h2>KORA WINTER</h2>
    <br>
    <template v-for="(event, index) in events">
      <p v-bind:key="event.location" :class="{'cancelled': event.ticket_info === 'CANCELLED'}">
        <strong>{{ event.location }}</strong><br>
        {{ event.date_text }}<br>
        {{ event.venue }}<span v-if="event.with"> | mit {{ event.with }}</span>
        <a v-if="event.ticket_info instanceof Object" :href="event.ticket_info.link" target="_blank" class="align-right">
          {{ event.ticket_info.text }}
        </a>
        <span v-else-if="event.ticket_info === 'CANCELLED'" class="align-right">
          CANCELLED
        </span>
        <span v-else class="align-right">
          {{ event.ticket_info }}
        </span>
      </p>
      <hr v-if="index !== events.length - 1" v-bind:key="event.date_text">
    </template>
    <br>
    <br>
    <br>
    <h2>Vergangene Tour Dates</h2>
    <br>
    <template v-for="(event, index) in pastEvents">
      <p v-bind:key="event.location" class="passed" :class="{
        'cancelled': event.ticket_info === 'CANCELLED'
      }">
        <strong>{{ event.location }}</strong><br>
        {{ event.venue }}<span v-if="event.with"> | mit {{ event.with }}</span>
        <span class="align-right">
          {{ event.date_text }}
        </span>
      </p>
      <hr v-if="index !== pastEvents.length - 1" v-bind:key="event.date_text">
    </template>
  </main>
</template>

<script>
import axios from 'axios';


export default {
  data() {
    return {
      events: [],
      pastEvents: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const manifest = await axios.get(process.env.BASE_URL + 'manifest.json');
        const response = await axios.get(process.env.BASE_URL + manifest.data['tourdates.json']);

        const { events, pastEvents } = response.data.reduce(
            (result, item) => {
              if (Date.parse(item.date) >= (new Date()).setHours(0, 0, 0, 0).valueOf()) {
                result.events.push(item);
              } else {
                if (item.ticket_info === 'CANCELLED') {
                  return result;
                }
                result.pastEvents.push(item);
              }

              return result;
            },
            { events: [], pastEvents: [] }
        );

        this.events = events;
        this.pastEvents = pastEvents.reverse();
      } catch (error) {
        console.error('Error loading data:', error);
      }
    },
  },
};
</script>

<style scoped>
  .container {
    position: relative;
    text-align: center;
    padding: 69px 20px 0 20px;
    max-width: 800px;
    margin: auto;
  }
  h1, h2 {
    text-align: left;
  }
  h1 {
    padding-top: 20px;
  }
  p {
    text-align: left;
    max-width: calc(100% - 100px);
  }
  p:last-child {
    padding-bottom: 30px;
  }
  .align-right {
    position: absolute;
    right: 20px;
    display: inline-block;
  }
  a {
    color: #e4252c;
    transition: 0.3s ease;
  }
  a:hover, a:focus {
    float: right;
    background-color: #333;
  }
  hr {
    margin: 9px 0;
  }

  .cancelled {
    color: #777;
    text-decoration: line-through;
  }

  .passed {
    color: #777;
  }

  /* MEDIA QUERIES */
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .container {
      text-align: justify;
      padding: 69px 20px 0 20px;
    }
    p {
      font-size: 1.2rem;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .container {
      text-align: justify;
      padding: 69px 20px 0 20px;
    }
    h1 {
      font-size: 3rem;
      padding-top: 20px;
    }
    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
</style>
